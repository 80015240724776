<template>
<div class="M_start_page">
   <HeaderB style="z-index:13"></HeaderB>
   <router-view />
</div>
</template>

<script>
import HeaderB from "./components/HeaderB";
export default {
   components: {
      HeaderB
   }
};
</script>

<style lang="scss" scoped>
.M_start_page {
   width: 100%;
   height: 100%;
   font-size: 12px;
   line-height: 24px;
}
</style>
